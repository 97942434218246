.Dekujeme-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0 20px;
}

.Dekujeme-content {
    width: stretch;
    max-width: 500px;
    position: relative;
    height: 50px;
    margin: 0 auto;
}

.Dekujeme-from-left {
    animation-name: fromLeft;
    animation-duration: 1000ms;
    top: 0;
    left: 0;
    position: absolute;
}

.Dekujeme-from-right {
    animation-name: fromRight;
    animation-duration: 1000ms;
    bottom: 0;
    right: 0;
    color: #ff9900;
    position: absolute;
}

@keyframes fromRight {
    from {
        right: -500px;
        opacity: 0;
    }
    to {
        right: 0;
        opacity: 1;
    }
}

@keyframes fromLeft {
    from {
        left: -500px;
        opacity: 0;
    }
    to {
        left: 0;
        opacity: 1;
    }
}