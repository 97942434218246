.Image-wrapper {
    flex: 1 1 auto;
    transition: 500ms ease-in-out;
    margin: 5px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;
}

.Image-cross-circle {
    position: absolute;
    top: -20px;
    right: 0px;
    height: 35px;
    width: 35px;
    border-radius: 25px;
    border: 3px solid #ff9900;
    background: #fff;
    box-shadow: 0px 0px 3px 3px rgba(255,255,255,1);
    cursor: pointer;
    transition: 300ms ease-in-out;
    color: #ff9900;
    font-size: 40px;
    font-weight: bolder;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.Image-prev {
    position: absolute;
    top: 48%;
    top: -webkit-calc(50% - 25px);
    top: -moz-calc(50% - 25px);
    top: calc(50% - 25px);
    left: 0;
    width: 50px;
    height: 50px;
    color: #ff9900;
    cursor: pointer;
    z-index: 1;
}

.Image-next {
    position: absolute;
    top: 48%;
    top: -webkit-calc(50% - 25px);
    top: -moz-calc(50% - 25px);
    top: calc(50% - 25px);
    right: 0;
    width: 50px;
    height: 50px;
    color: #ff9900;
    cursor: pointer;
    z-index: 1;
}

.Image-delete-icon {
    margin: 0 auto;
}

.Image-content {
    cursor: zoom-in;
    max-height: 100%;
    max-width: 95%;
    animation-name: imageWarpIn;
    animation-duration: 500ms;
    transition: 500ms ease-in-out;
    margin: auto;
}

@keyframes imageWarpIn {
    from {
      max-height: 0;
      max-width: 0;
    }
    to {
      max-height: 100%;
      max-width: 95%;
    }
}
