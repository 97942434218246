.Heading-wrapper {
    flex: 0 0 60px;
    width: stretch;
    max-width: 500px;
    position: relative;
}

.Heading-label {
    font-size: 1em;
    margin: 10px 0;
    background: black;
    color: #fff;
    border-left: 5px solid #ff9900;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: flyIn;
    animation-duration: 500ms;
    padding: 10px 20px 10px 10px;
    transition: 300ms ease-in-out;
}

@keyframes flyIn {
    from {
        left: -500px;
        opacity: 0;
    }
    to {
        left: 0;
        opacity: 1;
    }
}
