.App {
  text-align: center;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 300;
  font-style: normal;
  display: flex;
  flex-flow: column;
  width: stretch;
  height: 100vh;
}

.App-Content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  min-height: 450px;
}

.App-logo {
  height: 30px;
  margin: 20px 0;
}

.App-header {
  background: black;
  flex: 0 0 80px;
  width: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0 20px;
}

.App-Stepper-Content {
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
}

.App-header-wrapper {
  max-width: 1200px;
  text-align: left;
}

.Button-wrapper {
  margin: 20px auto;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: stretch;
  max-width: 460px;
  padding: 0 20px 20px;
}

.Stepper-wrapper {
  flex: 0 0 auto;
  width: auto;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.Stepper-step-circle {
  height: 30px;
  flex: 0 0 30px;
  background: #808080;
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  font-size: 13px;
  transition: 300ms ease-in-out;
  cursor: default;
}

.Stepper-step-active {
  background: black;
}

.Stepper-step-done {
  background: #ff9900;
  cursor: pointer;
}

.Stepper-divider {
  flex: 1 1 auto;
  margin: auto 10px;
  border: 1px solid #808080;
  transition: 500ms ease-in-out;
}

.Stepper-divider-done {
  border: 1px solid #ff9900;
}

.Stepper-step-inner {
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.Stepper-done-icon {
  flex: 0 0 auto;
  margin: 0 auto;
}