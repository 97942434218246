.Adresa-wrapper {
    width: stretch;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
}

.Adresa-input-wrapper {
    flex: 0 0 auto;
    display: flex;
    margin: 10px auto 20px;
    width: 100%;
    max-width: 500px;
}

.Adresa-heading {
    margin: 0;
    background: black;
    color: #fff;
    border-left: 5px solid #ff9900;
    position: absolute;
    top: -28px;
    left: 0;
    animation-name: HeaderFlyIn;
    animation-duration: 1s;
    padding: 10px 20px;
    transition: 300ms ease-in-out;
}

.Adresa-map {
    width: stretch;
    height: 30vh;
    margin: 0 auto;
}

.Adresa-inner {
    flex: 0 1 400px;
    height: inherit;
    max-height: 400px;
    margin: 0 auto;
}

.Adresa-map-wrapper {
    flex: 1 1 auto;
    width: auto;
    display: flex;
    padding: 0 20px;
}

@keyframes HeaderFlyIn {
    from {
        left: -500px;
        opacity: 0;
    }
    to {
        left: 0;
        opacity: 1;
    }
}