.Navod-wrapper {
    width: auto;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding: 0 20px;
}

.Navod-buttons {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 100%;
}

.Navod-content {
    margin: 0 auto;
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.Navod-moznost {
    margin: auto 0;
    /* cursor: pointer; */
}

.Navod-button-obrazek {
    max-width: 60%;
    max-height: 60%;
    margin: 0 auto;
}

.Navod-moznost-label {
    margin: 5px 0 0 0;
}

.Navod-moznost-picture {
    border: 2px solid #ff9900;
    -webkit-box-shadow: 5px 5px 10px 1px rgba(207,207,207,1);
    -moz-box-shadow: 5px 5px 10px 1px rgba(207,207,207,1);
    box-shadow: 5px 5px 10px 1px rgba(207,207,207,1);
    color: black;
    font-size: 30px;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.Navod-heading {
    background: black;
    color: #fff;
    border-left: 5px solid #ff9900;
    position: absolute;
    top: -53px;
    left: -15px;
    animation-name: flyIn;
    animation-duration: 500ms;
    padding: 10px 20px;
    transition: 300ms ease-in-out;
}

.Navod-text {
    overflow-y: scroll;
}

@keyframes flyIn {
    from {
        left: -500px;
        opacity: 0;
    }
    to {
        left: -15px;
        opacity: 1;
    }
}
