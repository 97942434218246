.Fotky-wrapper {
    width: auto;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    margin: 0 20px;
    transition: 500ms ease-in-out;
}

.Fotky-previews {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: stretch;
    padding-top: 10px;
    position: relative;
}

.Fotky-orange-border {
    position: absolute;
    right: 48%;
    right: -webkit-calc(50% - 68px);
    right: -moz-calc(50% - 68px);
    right: calc(50% - 68px);
    bottom: calc(50% - 68px);
    width: 120px;
    height: 120px;
    border: 3px solid #ff9900;
    border-radius: 80px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    cursor: pointer;
    animation-name: borderWarpIn;
    animation-duration: 750ms;
    transition: 500ms ease-in-out;
    margin: 20px 5px;
    font-size: 80px;
}

.Fotky-orange-border-files-non-empty {
    background: #ff9900;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    box-shadow: 5px 5px 10px 1px rgba(207,207,207,1);
    right: 48%;
    right: -webkit-calc(50% - 33px);
    right: -moz-calc(50% - 33px);
    right: calc(50% - 33px);
    bottom: -26px;
}

.Fotky-plus {
    color: #ff9900;
    font-weight: bolder;
    position: absolute;
    bottom: 14px;
    right: -15px;
    text-shadow: 2px 2px #fff;
    transition: 500ms ease-in-out;
}

.Fotky-plus-files-non-empty {
    bottom: 13px;
    right: 11px;
    transform: rotate(180deg);
    color: #fff;
    font-size: 49px;
    text-shadow: none;
}

.Fotky-camera {
    height: 60px;
    margin: 0 auto;
    animation-name: cameraWarpIn;
    animation-duration: 1000ms;
}

.Fotky-camera-files-non-empty {
    display: none;
}

@keyframes cameraWarpIn {
    from {
        height: 0;
        width: 0;
        opacity: 0;
    }
    to {
        height: 60px;
        width: 78.67px;
        opacity: 1;
    }
}

@keyframes borderWarpIn {
    from {
        height: 0;
        width: 0;
        right: 50%;
        right: 48%;
        right: -webkit-calc(50% + 68px);
        right: -moz-calc(50% + 68px);
        bottom: 50%;
        font-size: 0px;
    }
    to {
        right: 48%;
        right: -webkit-calc(50% - 68px);
        right: -moz-calc(50% - 68px);
        right: calc(50% - 68px);
        bottom: calc(50% - 68px);
        height: 120px;
        width: 120px;
        font-size: 90px;
    }
}