.Doplnky-wrapper {
    width: auto;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding: 0 20px;
    overflow-y: scroll;
}

.Doplnky-content {
    margin: 0 auto;
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.Doplnky-form {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    padding: 20px 0 0 0;
}