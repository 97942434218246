.loader-background {
  position: relative;
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 100000;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.loader-text {
  margin-top: 30px;
  font-weight: 700;
}

.loader-icon-wrapper {
  margin: auto auto;
  color: #ff9900;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  animation-name: rotate;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    transform: rotate(720deg);
  }
  to {
    transform: rotate(0deg);
  }
}